@tailwind base;
@tailwind components;
@tailwind utilities;

.highlight-title {
  background: linear-gradient(to right, #7dccff, #009cff);
  background-clip: text;
  color: transparent;
}
.sidebar-list .list-item {
  color: #fff;
  font-size: 14px;
  border-radius: 50px;
  padding-left: 16px;
  padding-right: 16px;
  display: flex;
}
.sidebar-list .inner-list-item {
  color: #fff;
  font-size: 14px;
  border-radius: 50px;
}
.sidebar-list .list-item:hover,
.sidebar-list .list-item:active,
.sidebar-list .list-item:visited,
.sidebar-list .list-item:focus,
.sidebar-list .accordionHeader:focus,
.sidebar-list .accordionHeader:visited {
  background-color: #009cff;
  color: #fff;
}
.drawer .sidebar {
  height: 100vh;
  border-radius: 0;
  padding-left: 16px;
  padding-right: 16px;
  padding-bottom: 16px;
  padding-top: 14px;
}


/* Custom Styles */

.text-shadow-1 {
  text-shadow: 0 1px 3px #00000070;
}

/* Table style */
.table-custom {
  border:2px dashed #ddd;
  color: #444;
  border-radius: 15px;
  padding: 10px;
  text-align: center;
  overflow: auto;
}
.table-custom th {
  background-color: #dddddd;
  color: #333;
  font-weight: 600;
  font-size: 13px;
  padding: 10px 15px;
  line-height: 17px;
}
.table-custom table th:first-child {
  border-top-left-radius: 10px;
  border-bottom-left-radius: 10px;
}
.table-custom table th:last-child {
  border-top-right-radius: 10px;
  border-bottom-right-radius: 10px;
}
.table-custom td {
  padding: 8px 13px;
  font-size: 13px;
}
.table-custom td:first-child,
.table-custom th:first-child {
  text-align: left;
}
.table-custom td:last-child,
.table-custom th:last-child {
  text-align: right;
}
.table-custom tr:nth-child(even) {
  background-color: #f7f7f7;
}
.table-custom tr:nth-child(even) td:first-child {
  border-top-left-radius: 10px;
  border-bottom-left-radius: 10px;
}
.table-custom tr:nth-child(even) td:last-child {
  border-top-right-radius: 10px;
  border-bottom-right-radius: 10px;
}
@media screen and (max-width: 767px) {
  .table-custom-body {
    overflow-x: scroll;
  }
}

/* Google translator */
#google_translate_element {
  position: fixed;
  z-index: 10000;
  bottom: 00px;
  right: 20px;
  width: 50px;
}
#google_translate_element span {
  display: none;
}
#google_translate_element .goog-te-combo {
  color: #000;
}
#google_translate_element select {
  width:50px;
  height: 50px;
  background-color: transparent;
  outline: none;
  font-size: 0px;
  background-image: url('Assets/translate.png');
  background-size: auto 50px;
  background-repeat: no-repeat;
  /* for Firefox */
  -moz-appearance: none;
  /* for Chrome */
  -webkit-appearance: none;
}
.goog-logo-link{
  display:none !important;
}
.goog-te-gadget{
  color:transparent!important;
}
.goog-te-banner-frame{
  display:none !important;
}
#goog-gt-tt, .goog-te-balloon-frame
{
display: none !important;
}
.goog-text-highlight 
{ 
background: none !important;
box-shadow: none !important;
}